import { saveAs } from "file-saver";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext, useState } from "react";

import { BorderButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { FormationPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Catalogue.module.css";
import { Filter } from "./components/Filter/Filter";
import { FormationCard } from "./components/FormationCard/FormationCard";

export const Catalogue = () => {
  const [active, setActive] = useState(0);
  const {
    storyText,
    jobRequirement,
    products,
    downloadLeafletLabel,
    pdf,
    all,
    hasPDF,
  } = useContext(FormationPageContext);

  const jobsArray = [{ name: all, id: "dummy" }, ...jobRequirement];

  const hasJob = ({ jobs, jobsAdvanced }) => {
    if (active === 0) return true;

    const arrayOfJobs = jobs?.map(({ name }) => name) ?? [];
    const arrayOfJobsAdvanced = jobsAdvanced?.map(({ name }) => name) ?? [];

    const arr = [...arrayOfJobs, ...arrayOfJobsAdvanced];

    return arr.includes(jobsArray[active].name);
  };

  let indice = 0;
  return (
    <section className={styles.root}>
      <div className={styles.overlayTriangle} />
      <div className={styles.story}>{renderRichText(storyText)}</div>
      <div className={styles.filter}>
        <Filter jobs={jobsArray} active={active} setActive={setActive} />
      </div>
      <div className={styles.container}>
        {products.map((product) => {
          if (hasJob(product)) {
            indice++;
            return (
              <div key={`${product.id}${Date.now()}`}>
                <SlideTransition thresholds={100} delay={0.3 * indice + 0.2}>
                  <FormationCard product={product} />
                </SlideTransition>
              </div>
            );
          }
        })}
      </div>
      {hasPDF && (
        <div className={styles.leaflet}>
          <div className={styles.footerPresentation}>
            <BorderButton
              label={downloadLeafletLabel}
              wide={false}
              width={6}
              responsive={false}
              onClick={() => {
                saveAs(pdf.file.url, pdf.file.fileName);
              }}
            />
          </div>
        </div>
      )}
    </section>
  );
};
