// extracted by mini-css-extract-plugin
export var bulletPoint = "FormationCard-module--bulletPoint--12457";
export var container = "FormationCard-module--container--30605";
export var firstLevel = "FormationCard-module--firstLevel--e510d";
export var head = "FormationCard-module--head--f574b";
export var headNotReady = "FormationCard-module--headNotReady--35d3e";
export var image = "FormationCard-module--image--c70fa";
export var notReady = "FormationCard-module--notReady--91864";
export var overlaySecond = "FormationCard-module--overlaySecond--8b9f0";
export var product = "FormationCard-module--product--0bceb";
export var secondLevel = "FormationCard-module--secondLevel--08c3a";
export var shortDescription = "FormationCard-module--shortDescription--42462";
export var subTitle = "FormationCard-module--subTitle--49e88";
export var transform = "FormationCard-module--transform--0e402";