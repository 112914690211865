import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { FormationPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const { formation, digitalTitle, vimeoUrl, qualiopi } =
    useContext(FormationPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.iframeWrapper}>
        <iframe
          src={`${vimeoUrl}&background=1`}
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
      <div className={styles.overlay} />
      <div className={styles.overlayTriangle} />
      <div className={styles.title}>
        <SlipTransition
          lines={[formation, digitalTitle]}
          color="#fff"
          important={true}
          options={{ delay: 0.4, animateY: -220 }}
        />
        <div className={styles.logo}>
          <GatsbyImage image={qualiopi.gatsbyImageData} alt="Qualiopi logo" />
        </div>
      </div>
    </section>
  );
};
