import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { Arrows } from "../../../../components/Arrows/Arrows";
import { Testimonial } from "./components/Testimonial";
import * as styles from "./Testimonials.module.css";

export const Testimonials = () => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  return (
    <div className={styles.root}>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
        springConfig={{
          duration: "0.5s",
          easeFunction: "ease-out",
          delay: "0s",
        }}
        className={styles.cursor_grab}
      >
        <Testimonial
          key={0}
          title={
            <>
              <b>Réussite</b> d&apos;Entreprise
            </>
          }
          image={
            <StaticImage
              src="../../../../resources/images/formation/stormshield.png"
              alt="vector"
              width={200}
            />
          }
          text={`" Formation complète enrichissante et articulée de manière à aborder progressivement les différents concepts du langage Gherkin au travers des cas concrets adaptés aux besoins et projet de l'équipe. La pédagogie active, la bienveillance et les compétences de la formatrice nous permettent d'en ressortir plus confiants et d'aborder nos projets à venir avec beaucoup de sérénité et de bonnes pratiques. En un mot : Merci ! "`}
          name="Mathieu Hérault"
          formation="Leader technique / Stormshield"
        />
        <Testimonial
          key={1}
          title={
            <>
              <b>Réussite</b> individuelle
            </>
          }
          text={`" Formation adaptée pour le besoin de notre équipe qui est de mettre en place l'ensemble des tests automatisés sur un projet qui a déjà plusieurs années, on a déjà des idées sur ce que l'on peut améliorer et comment le faire. "`}
          name="Cyril S."
          job="PO"
          formation="Formation BDD : Cucumber & Gherkin"
        />
      </SwipeableViews>
      <div className={styles.arrows}>
        <Arrows value={index} setValue={setIndex} max={2} />
      </div>
    </div>
  );
};
