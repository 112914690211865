// extracted by mini-css-extract-plugin
export var container = "Testimonial-module--container--d24d7";
export var content = "Testimonial-module--content--a384c";
export var content_center = "Testimonial-module--content_center--3543f";
export var formation = "Testimonial-module--formation--79263";
export var identity = "Testimonial-module--identity--561af";
export var image = "Testimonial-module--image--065a8";
export var testimonial = "Testimonial-module--testimonial--f1567";
export var testimonial_center = "Testimonial-module--testimonial_center--fbec6";
export var text = "Testimonial-module--text--e6e40";
export var title = "Testimonial-module--title--2b9ff";