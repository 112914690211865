import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { OverFooter } from "../../components/OverFooter/OverFooter";
import { FormationPageContext } from "../../helpers/pagesContext";
import { ZAC } from "../../helpers/route";
import { Appointment } from "./components/Appointment/Appointment";
import { Catalogue } from "./components/Catalogue/Catalogue";
import { Hero } from "./components/Hero/Hero";
import { Testimonials } from "./components/Testimonials/Testimonials";

export default function Formation() {
  const { footerBackground, node_locale, footerTitle, footerCta } =
    useContext(FormationPageContext);

  return (
    <div>
      <Hero />
      <Catalogue />
      <Testimonials />
      <Appointment />
      <OverFooter
        title={footerTitle}
        buttonLink={ZAC}
        buttonLabel={footerCta}
        image={footerBackground.file.url}
      >
        <Footer lang={node_locale} />
      </OverFooter>
    </div>
  );
}
