import clsx from "clsx";
import React from "react";

import * as styles from "./Testimonial.module.css";

export const Testimonial = ({ title, image, text, name, job, formation }) => {
  return (
    <div className={styles.testimonial}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.container}>
        {image && <div className={styles.image}>{image}</div>}
        <div className={clsx(styles.content, !image && styles.content_center)}>
          <p className={styles.text}>{text}</p>
          <div className={styles.identity}>
            <p>
              {name}
              {job ? "," : ""} {job}
            </p>
            <p className={styles.formation}>{formation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
