import clsx from "clsx";
import { Link } from "gatsby";
import React, { useContext, useState } from "react";

import { localeLink } from "../../../../../../helpers/localeLink";
import { FormationPageContext } from "../../../../../../helpers/pagesContext";
import { FORMATION } from "../../../../../../helpers/route";
import * as styles from "./FormationCard.module.css";

export const FormationCard = ({ product }) => {
  const { level, available, prerequisite, target } =
    useContext(FormationPageContext);
  const firstLevel = 1;
  const [activeLevel, setActiveLevel] = useState(firstLevel);

  const getBulletPoint = (activeLevel) => {
    const res =
      activeLevel === 1 ? product.bulletPoint : product.bulletPointAdvanced;
    return res?.map((bullet, i) => {
      return (
        <li key={i}>
          {i === res.length - 1 && (
            <span className={styles.subTitle}>{`${prerequisite} `}</span>
          )}
          {bullet.replaceAll("|", ",")}
        </li>
      );
    });
  };

  const getjobs = (activeLevel) => {
    const array = activeLevel === 1 ? product.jobs : product.jobsAdvanced;
    const res = array.map(({ name }) => {
      return name.trim();
    });
    return ` ${res.join(", ")}`;
  };

  return product.ready ? (
    <div
      className={clsx(
        styles.product,
        activeLevel === 1 ? styles.firstLevel : styles.secondLevel,
      )}
    >
      {activeLevel === 2 && <div className={styles.overlaySecond} />}
      <div className={styles.container}>
        <div className={styles.head}>
          {product.hasAdvanced && (
            <>
              <div onClick={() => setActiveLevel(1)}>{level} I</div>
              <div onClick={() => setActiveLevel(2)}>{level} II</div>
            </>
          )}
        </div>
        <Link to={localeLink(FORMATION + `/${product.url}?${activeLevel}`)}>
          <img
            className={styles.image}
            src={
              activeLevel === 1
                ? product.logo.file.url
                : product.logoAdvanced.file.url
            }
            alt={
              activeLevel === 1
                ? product.logo.filename
                : product.logoAdvanced.filename
            }
          />
          <div className={styles.shortDescription}>
            <h3>
              {activeLevel === 1
                ? product.shortDescription
                : product.shortDescriptionAdvanced}
            </h3>
          </div>
          <ul className={styles.bulletPoint}>
            {getBulletPoint(activeLevel)}
            <li>
              <span className={styles.subTitle}>{target}</span>
              {getjobs(activeLevel)}
            </li>
          </ul>
        </Link>
      </div>
    </div>
  ) : (
    <div className={clsx(styles.product, styles.notReady)}>
      <div className={styles.headNotReady}>
        <div>{available}</div>
      </div>
      <div className={styles.container}>
        <img
          className={styles.image}
          src={product.logoAdvanced.file.url}
          alt={product.logoAdvanced.filename}
        />
        <div className={styles.shortDescription}>
          <div>{product.shortDescription}</div>
        </div>
        <ul className={styles.bulletPoint}>
          {getBulletPoint(firstLevel)}
          <li>
            <span className={styles.subTitle}>{target}</span>
            {getjobs(firstLevel)}
          </li>
        </ul>
      </div>
    </div>
  );
};
