import { Link } from "gatsby";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { FormationPageContext } from "../../../../helpers/pagesContext";
import { FORMATION, FORMATION_CONTACT } from "../../../../helpers/route";
import * as styles from "./Appointment.module.css";

export const Appointment = () => {
  const { appointmentHeadTitle, appointmentDescription, appointmentCta } =
    useContext(FormationPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.overlay}>
        <div className={styles.description}>
          <SlideTransition thresholds={100} delay={0.2}>
            <div className={styles.descriptionHead}>{appointmentHeadTitle}</div>
            <div className={styles.descriptionText}>
              {appointmentDescription}
            </div>
          </SlideTransition>
          <SlideTransition thresholds={100} delay={0.2}>
            <Link
              to={localeLink(FORMATION_CONTACT)}
              state={{ prevPath: `${FORMATION} ` }}
            >
              <div className={styles.cta}>
                <div>
                  <span>{appointmentCta}</span>
                </div>
              </div>
            </Link>
          </SlideTransition>
        </div>
      </div>
    </section>
  );
};
