import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { FormationPageContext } from "../../helpers/pagesContext";
import { FORMATION } from "../../helpers/route";
import FormationScene from "../../scenes/Formation/Formation";

export default function Formation(props) {
  return (
    <Layout currentRoute={FORMATION} data={props.data.contentfulMenuLabel}>
      <Seo
        title="Formation"
        description="La Zenity Academy, certifiée Qaliopi, propose des formations telles que les API, Cucumber, Gherkin, Jira, Xray, Jenkins, Agiles, Selenium, bdd, DevOps, tests exploratoires, tests logiciels..."
      />
      <FormationPageContext.Provider value={props.data.contentfulFormations}>
        <FormationScene />
      </FormationPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulFormations(node_locale: { eq: $locale }) {
      appointmentHeadTitle
      prerequisite
      target
      level
      available
      all
      level
      appointmentDescription
      appointmentCta
      node_locale
      footerBackground {
        file {
          url
        }
      }
      footerTitle
      footerCta
      storyText {
        raw
      }
      jobRequirement {
        name
        id
      }
      formation
      digitalTitle
      vimeoUrl
      downloadLeafletLabel
      hasPDF
      pdf {
        id
        file {
          url
          fileName
        }
      }
      qualiopi {
        gatsbyImageData
        file {
          url
        }
      }
      products {
        id
        url
        ready
        name
        hasAdvanced
        jobs {
          name
        }
        jobsAdvanced {
          name
        }
        shortDescription
        shortDescriptionAdvanced
        bulletPoint
        logo {
          gatsbyImageData
          file {
            url
            fileName
          }
        }
        bulletPointAdvanced
        logoAdvanced {
          file {
            fileName
            url
          }
        }
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
