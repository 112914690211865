import clsx from "clsx";
import React from "react";

import mark from "../../../../../../resources/images/formation-misc/mark.svg";
import * as styles from "./Filter.module.css";

export const Filter = ({ jobs, active, setActive }) => {
  return (
    <div id="filter" className={styles.root}>
      {jobs.map(({ name, id }, i) => {
        return (
          <div
            key={id}
            className={clsx(styles.job, i === active && styles.activeMark)}
            onClick={() => setActive(i)}
          >
            {i === active && (
              <div className={styles.jobMark}>
                <img src={mark} alt="selector" width="10" />
              </div>
            )}
            <h2>{name}</h2>
          </div>
        );
      })}
    </div>
  );
};
